.tooltip-container {
  position: relative;
  display: inline;

  a:hover {
    display: initial; }

  .tooltip {
    position: absolute;
    top: 100%;
    width: 300px;
    left: 0;
    z-index: 50; }


  @media screen and (max-width: 768px) {
    .tooltip {
      display: none; } } }
