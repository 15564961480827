@import '../variables.sass';

header {
  background: rgb(27, 27, 29);
  background: $gradient;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 2ch;
  border-bottom: 1px solid #52544f; }

footer {
  background: rgb(27, 27, 29);
  background: $gradient;
  border-top: 4px solid $orange;
  padding: 4ch;
  text-align: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px; }

  .ui.list {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column; }

    a.item {
      flex: 1 auto; }

    >a.item, a.item>i.icon {
      color: #bb7e3b;

      &:hover {
        color: #eaa65c; } } }

  .top-link {
    position: fixed;
    bottom: 10px;
    right: 10px; } }

.Logo {
  width: 100%;
  pointer-events: none; }

.Navigation {
  display: grid;
  grid-template-rows: 100px auto;
  background: $green-dark;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-rows: 48px;

    header {
      display: none; } } }

.Navigation-main {
  display: flex; }

.Navigation-link {
  flex: 1 auto;
  padding: 2ch 1ch;
  color: $font-color-base;
  font-family: 'almendra_scbold', serif;
  text-align: center;

  &.hide-mobile {
    display: block; }

  &.only-mobile {
    display: none; }

  @media screen and (max-width: 768px) {
    span {
      display: none; }

    &.only-mobile {
      display: block; }

    &.hide-mobile {
      display: none; } } }


.Navigation-link:hover, .Navigation-link.active {
  background: rgba(255, 255, 255, 0.05);
  color: $orange; }

.Content {
  padding: 2ch;
  min-height: calc(100vh - 59px);

  @media screen and (max-width: 768px) {
    padding: 2ch 0;

    .column {
      padding: 1ch 0 !important; } } }


.sidebar {
  min-height: 160px; }

@media screen and (min-width: 768px) {
  .Container {
    padding-left: 300px; }

  .Navigation {
    top: 0;
    flex-direction: column;
    width: 300px;
    border-right: 1px solid $green-light;
    overflow-y: auto; }

  .Navigation-main {
    flex-direction: column; }

  .Navigation-link {
    flex: unset;
    border-bottom: 1px solid $green-dark;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
    border-right: 2px solid transparent;
    text-align: left;

    i {
      float: right; } }

  .Navigation-link:hover, .Navigation-link.active {
    border-right-color: $orange; } }


.Navigation-heading {
  padding: 5px 10px;

  @media screen and (max-width: 768px) {
    display: none; } }

.Navigation-bottom-links {
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  font-size: 16pt;

  @media screen and (max-width: 768px) {
    display: none; }

  .Navigation-link {
    flex: 1 auto;
    text-align: center;

    &:hover, &.active {
      border-right-color: transparent; } } }


.ui.segment {
  background-color: $green-med;
  border-color: $green-light; }


.Page-header {
  padding: 2vh 3vh;
  background: url('assets/images/bridgepour.png') no-repeat center center / cover;
  border-radius: 4px;
  box-shadow: 0 4px 5px rgba(0,0,0,0.4);
  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 2vh; }

  h1 {
    color: $orange;
    background: rgba(0,0,0,0.4);
    text-shadow: -1px -1px 0 $orange-light, 2px 2px 2px rgba(0,0,0,1);
    padding: 1ch;
    backdrop-filter: blur(3px);
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 4px;
    box-shadow: 0 0 35px rgba(0,0,0,0.4) inset;


    .sub-heading {
      color: rgba(255,255,255,0.9);
      text-shadow: none;
      font-size: 16px;
      font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: normal;
      line-height: 20px; } }

  &.getting-started {
    background: url('assets/images/ember-cradle.jpg') no-repeat center center / cover; } }


.Town-list {
  .segment {
    background: #3e463c no-repeat center right / cover;
    transition: border-color 0.1s;

    &.bastion-keep {
      background-image: url("assets/images/bastion-keep-entrance.png"); }

    &.burnscroft {
      background-image: url("assets/images/burnscroft-entrance.png"); }

    &.ironil {
      background-image: url("assets/images/ironil-entrance.png"); }

    &.bridgepour {
      background-image: url("assets/images/bridgepour-entrance.png"); }

    @media screen and (max-width: 768px) {
      background-position: center; } } }


.ui.horizontal.link.list .item {
  padding: 0 1ch 0 0;
  border-radius: 4px;

  .header {
    color: $orange; }

  &:hover, &.active {
    background: rgba(0,0,0,0.1);

    .header {
      color: $orange-light; } } }

.text-right {
  text-align: right !important; }

.workshop-banner {
  height: 100px;
  background:  url("assets/images/workshop.png") no-repeat left center / cover;
  display: block;
  opacity: 0.8;
  transition: opacity 0.1s;

  ~ .ui.segment {
    margin-top: 0; }

  &:hover {
    opacity: 1; } }

.ui.card .content img {
  width: 100%; }

.subheader {
  background: rgba(0,0,0,0.1) !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(255,255,255,0.1) !important;

  form {
    width: 300px;
    margin: 0;

    input {
      border-radius: 0 !important;
      height: 40px; } }

  @media screen and (max-width: 768px) {
    display: none !important; } }

.redacted {
  position: relative;
  white-space: pre;
  &:after {
    background: $green-dark;
    border-radius: 0.1em;
    box-shadow: 0 0 1px rgba(0,0,0,0.35);
    content: " ";
    width: 100%;
    height: 1.4em;
    left: 0;
    position: absolute;
    transform: skewY(5deg) rotate(-6deg); }

  &:active:after {
    background: none; } }

.hidden-mobile {
  display: block;

  @media screen and (max-width: 768px) {
    display: none !important; } }
