.Digsite-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2ch;
  margin-bottom: 4ch;
  text-align: center;

  .Digsite-header {
    padding: 6vh 2vh;
    margin-bottom: 2ch;

    &.ember-cradle {
      background: url("assets/images/digsite-ember-cradle.png") no-repeat center / cover; }

    &.mildews-aquifer {
      background: url("assets/images/digsite-mildews-aquifer.png") no-repeat center / cover; }

    &.dreck-quarry {
      background: url("assets/images/digsite-dreck-quarry.png") no-repeat center / cover; }

    &.south-hope {
      background: url("assets/images/digsite-south-hope.png") no-repeat center / cover; }

    h3 {
      color: #fff;
      background: rgba(0, 0, 0, 0.4);
      padding: 1ch;
      backdrop-filter: blur(3px);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.4) inset; } }

  .Digsite-description {
    padding-bottom: 1ch;
    margin-bottom: 1ch;
    min-height: 80px; }

  @media screen and (max-width: 1360px) {
    grid-template-columns: 1fr 1fr; }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; }

  .ui.segment {
    margin: 0;
    box-shadow: 0 5px 3px rgba(0, 0, 0, 0.1);

    .digsite-item {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      border-top: 1px solid #525950; } }

  .digsite-relic-list {
    display: flex;

    .tooltip-container {
      flex: 1 auto;
      a {
        display: block;
        padding: 6px 12px;
        border: 1px solid #bb7e3b;
        margin: 6px;
        border-radius: 4px; } } } }
