@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

@import './variables.sass';

@font-face {
  font-family: 'almendra_scbold';
  src: url('almendrasc-bold-webfont.woff2') format('woff2'),
  url('almendrasc-bold-webfont.woff') format('woff') {}
  font-weight: bold;
  font-style: normal; }

h1, h2, h3, h4, h5 {
  font-family: 'almendra_scbold', serif; }

a {
  color: #bb7e3b;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    color: #eaa65c; } }

.ui.brand.button {
  color: rgba(255,255,255,0.8);
  border: 1px solid #bb7e3b;
  border-radius: 3px;
  padding: 1ch !important;
  background: rgba(187, 126, 59, 0.6);

  &:hover {
    color: #fff;
    background: rgba(187, 126, 59, 0.8); } }

.ib {
  font-family: 'Permanent Marker', cursive;
  font-size: 24px; }

.Loader-container {
  width: 100%;
  border-radius: 8px;
  background: rgba(0,0,0,0.1);
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center; }

.ui.form .field {
  >input {
    font-family: 'almendra_scbold', serif;
    font-weight: normal;
    color: #bb7e3b;
    font-size: 20px;
    padding: 0.5ch 1ch;
    background: #343c31;
    border: 1px solid #52544f;

    &:focus, &:active, .active {
      background: #1f221e;
      border-color: #bb7e3b;
      color: #fff;
      outline: 2px solid transparent; } }

  >label {
    font-family: 'almendra_scbold', serif;
    font-weight: normal;
    color: #bb7e3b;
    font-size: 20px; } }

.ui.breadcrumb {
  margin-bottom: 16px;

  .section {
    text-transform: capitalize; }

  a {
    color: #bb7e3b;
    &:hover {
      color: #eaa65c; } } }

::selection {
  background: rgba(187, 126, 59, 0.25);
  color: #fff; }

::-webkit-scrollbar {
  width: 4px; }

::-webkit-scrollbar-track {
  background: #1f221e; }

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #bb7e3b; }

.muted {
  opacity: 0.6;
  font-size: 80%; }

.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2ch;
  //text-align: center

  .ui.button {
    text-align: left; }

  .label {
    float: right;
    text-transform: uppercase;
    font-size: 60%;
    margin-left: 3rem;
    background: rgba(255,255,255,0.1);
    color: #fff;
    padding: 0.3em 0.6em;
    border-radius: 4px; }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; } }

.alice-carousel__stage-item {
  margin: 0 2ch;

  img {
    border-radius: 8px;
    box-shadow: 0 5px 3px rgba(0, 0, 0, 0.1);
    width: 100%; } }

.alice-carousel__dots-item, .alice-carousel__dots-item {
  background-color: #1b1b1d !important; }

.alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
  background-color: #bb7e3b !important; }

kbd {
  display: inline-block;
  color: #bb7e3b;
  font-family: 'almendra_scbold', serif;
  background: #251a14;
  background-size: 14px;
  border: 3px solid #a2716c;
  box-shadow: 0 0 1px 1px #21161c, 0 0 1px 1px #21161c inset;
  border-radius: 1px;
  text-align: center;
  margin: 0 .5ch;
  padding: 0 .5ch; }

.search-input-min {
  margin: 1ch;
  width: 250px;

  @media screen and (max-width: 768px) {
    display: none; }

  .ui.input>input {
    color: #fff;
    background-color: rgba(255,255,255,0.1); }

  .ui.input.icon>i {
    color: #fff; } }

.search-input-max {
  .ui.input>input {
    color: #fff;
    background-color: rgba(255,255,255,0.1);
    font-size: 3ch;
    padding: 0.5ch 1ch; }

  .ui.input.icon>i {
    color: #fff; } }

progress {
  border: none;
  height: 12px; }

progress::-webkit-progress-value {
  background: #bb7e3b;
  border-radius: 2px; }

progress::-webkit-progress-bar {
  background: #1f221e;
  border-radius: 2px; }

.ui.message.inverted {
  border-top: 2px solid #bb7e3b;
  border-bottom: 2px solid #bb7e3b;
  font-weight: bold;
  text-align: center; }
