@import '../../variables.sass';

.Item-amount {
  position: absolute;
  text-transform: lowercase;
  right: 5px;
  text-align: center;
  top: 5px;
  background: $green-dark;
  color: $font-color-base;
  border: 1px solid #bb7e3b;
  line-height: 22px;
  font-size: 12px;
  border-radius: 4px;
  padding: 3px 7px; }

.ui.card, .ui.cards>.card {
  background: $green-dark;
  border-color: $green-dark;
  box-shadow: 0 1px 3px 0 $green-dark, 0 0 0 1px $green-med;
  transition: all 0.1s;

  >.image {
    background: url('assets/images/item-mid.jpg') no-repeat center center / cover; }

  .content {
    .header {
      color: #fff; }
    .meta {
      color: $font-color-base / 2;
      text-transform: uppercase;
      font-size: 70%; }
    .description {
      color: $font-color-base; } }

  >.extra, >.extra {
    color: $orange;
    font-weight: bold; } }

.ui.cards a.card:hover, .ui.link.card:hover, .ui.link.cards .card:not(.icon):hover, a.ui.card:hover {
    background: $green-med;
    border-color: $green-dark;
    box-shadow: 0 1px 3px 0 $green-dark, 0 0 0 1px $green-med; }
