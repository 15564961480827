$orange-light: #eaa65c;
$orange: #bb7e3b;
$orange-dark: #8d581f;
$green-light: #52544f;
$green-med: #3e463c;
$green: #343c31;
$green-dark: #1f221e;
$border-radius: 4px;
$gradient: linear-gradient(56deg,#1b1b1d,#151723 50%,#3f2820 75%,#151723);
$font-size-base: 14px;
$font-color-base: #f5efd7;
