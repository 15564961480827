.Blog {
  &-post {
    position: relative;
    display: flex;

    @media screen and (max-width: 768px) {
      display: block; }

    .Blog-post-timestamp {
      font-size: 75%;
      background: rgba(0,0,0,0.4);
      padding: 1ch 2ch;

      @media screen and (max-width: 768px) {
        display: none; } }

    .Blog-post-image {
      margin-right: 2ch;
      img {
        max-width: 210px;
        border: 1px solid #525950; }


      @media screen and (max-width: 768px) {
        margin: 0;
        img {
          max-width: 100%; } } }

    p {
      margin: 2ch 0; }

    &.full, &:first-child {
      padding: 0;
      .Blog-post-flexer {
        flex-direction: column; }
      .Blog-post-image img {
        max-width: 100%; }
      .Blog-post-content {
        padding: 2ch; } }

    .Blog-post-content {
      overflow: hidden; } } }

.Blog-feed .Blog-post:first-child {
  display: block;
  .Blog-post-image {
    margin: 0;
    img {
      width: 100%; } } }

.blog-post img {
  max-width: 100%; }
