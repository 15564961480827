.Item-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2ch;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr; }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr; }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr; }

  &.two-wide {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr; } } }


.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 79vh;
  position: relative;

  &:before {
    position: absolute;
    width: 444px;
    height: 165px;
    content: '';
    bottom: -20px;
    right: 0;
    background: url("assets/images/forgotten-shovel.png") no-repeat bottom right;
    z-index: -5; }

  h1 {
    font-size: 40vmin; }

  img {
    margin: -10vmin;
    width: 40vmin;
    height: 40vmin; } }

.contact-options {
  .column {
    text-align: center;

    i {
      font-size: 80pt;
      opacity: 0.6;
      margin-bottom: 20px; } } }

.text-lead {
  font-weight: bold;
  font-size: 115%; }

.rap {
  margin: 2ch 0;

  .rap-link {
    margin-top: 12px;
    text-align: right;

    a {
      font-family: "Almendra SC", serif;
      padding: 8px; } } }

.ui.header {
  font-family: 'almendra_scbold', serif;
  color: #bb7e3b;
  .sub.header {
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: #fff;
    opacity: 0.6;
    font-size: 50% !important;
    letter-spacing: 2px;
    text-transform: uppercase; } }

.ui.inverted.items>.item>.content {
  .header {
    color: #f5efd7 !important; }
  .description {
    opacity: 0.8; } }


.orange {
  color: #bb7e3b; }

.red {
  color: red; }

.blue {
  color: blue; }

.green {
  color: green; }

img {
  max-width: 100%; }

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url], .ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
  background: #1f221e;
  color: #fff; }

.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
  border-color: #52544f;
  color: #fff; }
